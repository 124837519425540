import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import style from "./index.module.scss"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <section className={style.row}>
      <article className={style.container}>
        <h1 className={style.title}>
          Hey there,
          <br />
          I'm Andrew Harris
        </h1>
        <p className={style.description}>
          I'm a front end web developer with a penchant for design and
          animation.
        </p>
      </article>
      <Image className={style.image} path={`me_nobackground.png`} />
    </section>
  </Layout>
)

export default IndexPage
